<template lang="pug">
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ 'pages.administration.update_access_token' | translate }}
    v-card-content
      .form-group
        label {{ 'ui.labels.name' | translate}}
        input.form-control(
          name="state"
          :placeholder="'ui.inputs.enter_name' | translate"
          v-model="title")
      .form-group
        label {{ 'ui.labels.expiration' | translate}}
        v-select(
          v-if="!loading"
          single
          track-by="title"
          item-value="id"
          hideBottomMargin
          :placeholder="'ui.labels.select_expiration' | translate"
          :options="expiringOptions"
          v-model="expiration")
    v-card-actions
      v-btn(
        text
        @click="$emit('close')") {{ 'ui.buttons.cancel' | translate }}
      v-btn(
        @click="onConfirm") {{ 'ui.buttons.save' | translate }}

</template>

<script>
import moment from 'moment'

import api from '@/api'

import successIcon from '@/components/svg/CreateTokenModal_SuccessIcon'

export default {
  name: 'UpdateTokenModal',

  props: {
    tokenData: Object
  },

  components: {
    successIcon
  },

  data: () => ({
    title: '',
    expiration: {
      id: '0'
    },
    loading: true,
    expiringOptions: null
  }),

  async mounted () {
    this.title = this.tokenData.title
    const { data } = await api.settings.tokens.getExpiringList()
    this.expiringOptions = Object.keys(data)
      .map((key) => {
        return { id: key, title: data[key] }
      })
    this.expiration = this.expiringOptions.find(item => item.id === this.tokenData.expiration.toString())
    this.loading = false
  },

  methods: {
    convertedData (data) {
      return moment(data).format('DD.MM.YYYY')
    },

    async onConfirm () {
      const apidata = {
        title: this.title,
        isActive: this.tokenData.isActive,
        expiration: +this.expiration.id
      }
      const { data } = await api.settings.tokens.updateToken(apidata, this.tokenData.id)
      if (data.accessToken) {
        this.$emit('on-update')
      }
    }
  }

}
</script>

<style lang="scss">
.copyToken {
  width: 250px;
  overflow: hidden;
  background: #F0F2FA;
  padding: 7px 10px;
  height: 100%;
  margin: 0;
  text-overflow: ellipsis;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.copyBtn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
