import { render, staticRenderFns } from "./PersonalTokens_emptyIcon.vue?vue&type=template&id=c3b88c22&scoped=true&functional=true&"
import script from "./PersonalTokens_emptyIcon.vue?vue&type=script&lang=js&"
export * from "./PersonalTokens_emptyIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "c3b88c22",
  null
  
)

export default component.exports