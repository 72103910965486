<template lang="pug">
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ 'pages.administration.create_new_access_token' | translate }}
        p.subtitle(v-if="success") {{ 'pages.administration.create_new_access_token_successful' | translate }}
    v-card-content(v-if="!success")
      form.kt-form(id="createToken" @submit.prevent="onConfirm")
        .form-group
          label {{ 'ui.labels.name' | translate}}
          input.form-control(
            name="state"
            :placeholder="'ui.inputs.enter_name' | translate"
            v-model="tokenData.title")
          span.form-text.invalid {{ 'title' | errorText }}
        .form-group
          label {{ 'ui.labels.expiration' | translate}}
          v-select(
            v-if="!loading"
            single
            track-by="title"
            item-value="id"
            hideBottomMargin
            :placeholder="'ui.labels.select_expiration' | translate"
            :options="expiringOptions"
            v-model="tokenData.expiration")
          v-skeleton(v-else width="100%" )
          span.form-text.invalid {{ 'expiration' | errorText }}
    v-card-content(v-else)
      success-icon
    v-card-actions(v-if="!success")
      v-btn(
        text
        @click="$emit('close')") {{ 'ui.buttons.cancel' | translate }}
      v-btn(type="submit" form="createToken") {{ 'ui.buttons.next' | translate }}
    v-card-actions(v-else)
      label.copyToken {{ token }}
      v-btn.copyBtn(
        @click="onCopy") {{ 'ui.buttons.copy' | translate }}

</template>

<script>
import api from '@/api'

import successIcon from '@/components/svg/CreateTokenModal_SuccessIcon'

export default {
  name: 'CreateTokenModal',

  components: {
    successIcon
  },

  data: () => ({
    success: false,
    tokenData: {
      title: '',
      expiration: null
    },
    expiringOptions: [],
    loading: true,
    token: null
  }),

  async mounted () {
    const { data } = await api.settings.tokens.getExpiringList()
    this.expiringOptions = Object.keys(data)
      .map((key) => {
        return { id: key, title: data[key] }
      })
    this.loading = false
  },

  methods: {
    async onConfirm () {
      const apidata = {
        title: this.tokenData.title,
        expiration: this.tokenData?.expiration?.id
      }
      const { data } = await api.settings.tokens.generateToken(apidata)
      if (data?.accessToken) {
        this.success = true
        this.token = data.accessToken
        this.$emit('on-update')
      }
    },

    onCopy () {
      this.$copyText(this.token)
      this.$emit('close')
    }
  }

}
</script>

<style lang="scss">
.copyToken {
  width: 250px;
  overflow: hidden;
  background: #F0F2FA;
  padding: 7px 10px;
  height: 100%;
  margin: 0;
  text-overflow: ellipsis;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.copyBtn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
