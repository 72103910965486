<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" width="16" height="16" fill="white"/>
    <path d="M1.76307 10.3032C1.70376 10.3627 1.66228 10.4377 1.64334 10.5196L0.821583 14.0735C0.792235 14.208 0.793241 14.3474 0.824527 14.4814C0.855813 14.6155 0.91659 14.7408 1.00242 14.8483C1.08824 14.9558 1.19695 15.0428 1.3206 15.1028C1.44425 15.1628 1.57971 15.1944 1.7171 15.1952C1.78112 15.2017 1.84562 15.2017 1.90964 15.1952L5.47848 14.3716C5.56028 14.3527 5.63517 14.3112 5.69463 14.2519L12.6961 7.26238L8.73795 3.30493L1.76307 10.3032Z" fill="#A0AAC3"/>
    <path d="M14.9262 3.71769L12.2844 1.07042C12.1107 0.897258 11.8757 0.800049 11.6307 0.800049C11.3857 0.800049 11.1506 0.897258 10.9769 1.07042L9.5083 2.54213L13.462 6.50406L14.9307 5.03236C15.0166 4.94579 15.0847 4.8431 15.131 4.73015C15.1773 4.61719 15.2009 4.4962 15.2005 4.37409C15.2001 4.25199 15.1756 4.13116 15.1285 4.01853C15.0815 3.90589 15.0127 3.80367 14.9262 3.71769Z" fill="#A0AAC3"/>
  </svg>
</template>

<script>
export default {
  name: 'PencilIcon'
}
</script>

<style scoped>

</style>
