<template functional>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" width="16" height="16" fill="white"/>
    <rect opacity="0.01" x="0.799805" y="0.800049" width="14.4" height="14.4" fill="#F8F9FC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72089 1.23537C5.85429 0.968576 6.12698 0.800049 6.42527 0.800049H9.57531C9.8736 0.800049 10.1463 0.968576 10.2797 1.23537L10.512 1.70005H13.5127C13.9476 1.70005 14.3002 2.05262 14.3002 2.48755V2.71255C14.3002 3.14747 13.9476 3.50005 13.5127 3.50005H2.48771C2.05278 3.50005 1.7002 3.14747 1.7002 2.71255V2.48755C1.7002 2.05262 2.05278 1.70005 2.48771 1.70005H5.48855L5.72089 1.23537ZM2.54048 4.50005H13.4601L12.7233 14.47C12.6931 14.8815 12.3505 15.2 11.9378 15.2H4.06273C3.6501 15.2 3.30743 14.8815 3.27732 14.47L2.54048 4.50005Z" fill="#A0AAC3"/>
  </svg>
</template>

<script>
export default {
  name: 'TrashBoxIcon'
}
</script>

<style scoped>

</style>
