<template functional>
  <svg :class="[data.staticClass, data.class]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.66409 7.33549L8.6688 3.28613C8.6688 3.10859 8.59828 2.93833 8.47274 2.81279C8.3472 2.68726 8.17694 2.61673 7.99941 2.61673C7.82187 2.61673 7.65161 2.68726 7.52607 2.81279C7.40054 2.93833 7.33001 3.10859 7.33001 3.28613L7.33473 7.33549L3.28536 7.33078C3.10783 7.33078 2.93756 7.4013 2.81203 7.52684C2.68649 7.65237 2.61597 7.82264 2.61597 8.00017C2.61597 8.17771 2.68649 8.34797 2.81203 8.47351C2.93756 8.59904 3.10783 8.66957 3.28536 8.66957L7.33473 8.66485L7.33001 12.7142C7.32965 12.8022 7.34672 12.8894 7.38023 12.9708C7.41375 13.0522 7.46304 13.1261 7.52527 13.1884C7.5875 13.2506 7.66144 13.2999 7.74282 13.3334C7.82419 13.3669 7.9114 13.384 7.99941 13.3836C8.08741 13.384 8.17462 13.3669 8.256 13.3334C8.33738 13.2999 8.41131 13.2506 8.47355 13.1884C8.53578 13.1261 8.58507 13.0522 8.61858 12.9708C8.6521 12.8894 8.66916 12.8022 8.6688 12.7142L8.66409 8.66485L12.7135 8.66957C12.8015 8.66993 12.8887 8.65286 12.97 8.61935C13.0514 8.58584 13.1254 8.53654 13.1876 8.47431C13.2498 8.41208 13.2991 8.33814 13.3326 8.25677C13.3661 8.17539 13.3832 8.08818 13.3828 8.00017C13.3832 7.91217 13.3661 7.82496 13.3326 7.74358C13.2991 7.6622 13.2498 7.58827 13.1876 7.52603C13.1254 7.4638 13.0514 7.41451 12.97 7.381C12.8887 7.34749 12.8015 7.33042 12.7135 7.33078L8.66409 7.33549Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon'
}
</script>
