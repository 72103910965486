<template lang="pug">
  v-card.accessTokens
    v-scrollable(:bottom-offset="20")
      v-card-content
        .wrapper
          h2.title {{ 'pages.settings.personal_access_token' | translate }}
          p.subtitle {{ 'infotext.personal_token_description' | translate }}

          empty-table-icon(v-if="!tokensList.length && loaded").emptyIcon
          v-table.table-fixed(v-else)
            v-table-head
              th.table-sort-arrows-hover(
                @click="onSortTable('title', sortedLabels.title)"
              )
                | {{ 'ui.labels.token_name' | translate }}
                v-table-sorter(
                  :show="currentSortLabel === 'title'"
                  :toggled="sortedLabels.title")

              th.table-sort-arrows-hover(style="width:150px" @click="onSortTable('isActive', sortedLabels.isActive)")
                | {{ 'ui.labels.status' | translate }}
                v-table-sorter(
                  :show="currentSortLabel === 'isActive'"
                  :toggled="sortedLabels.isActive")

              th.table-sort-arrows-hover(
                @click="onSortTable('expiresAt', sortedLabels.expiresAt)"
                style="width:250px"
              )
                | {{ 'ui.labels.expiration' | translate }}
                v-table-sorter(
                  :show="currentSortLabel === 'expiresAt'"
                  :toggled="sortedLabels.expiresAt")
              th
                | {{ 'ui.labels.actions' | translate }}
              th.table-sort-arrows-hover(style="width:100px")

            v-table-body(
              :cols="5"
              :loaded="loaded")
              v-table-row(
                v-for="(item, index) in tokensList"
                :key="index")
                td.align-left
                  span {{ item.title }}
                td.align-left
                  span(v-if="item.isActive")
                    svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg").statusDot
                      rect(opacity="0.01" width="16" height="16" fill="white")
                      circle( cx="8.00029" cy="8.00005" r="3.2" fill="#29A687")
                    | {{ 'pages.administration.active' | translate}}
                  span(v-else)
                    svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg").statusDot
                      rect(opacity="0.01" width="16" height="16" fill="white")
                      circle( cx="8.00029" cy="8.00005" r="3.2" fill="#F85061")
                    | {{ 'ui.labels.not_active' | translate}}

                td.align-left {{ convertedData(item.expiresAt) }}
                td.text-right
                  .flex
                    span.button-element(
                      v-tooltip="$t('ui.buttons.edit')"
                      @click="editToken(index)"
                    )
                      iconEdit
                    span.button-element(
                      v-tooltip="$t('ui.buttons.delete')"
                      @click="deleteToken(item.id)"
                    )
                      deleteIcon
                td
                  v-switch(
                    :value="item.isActive"
                    @input="toggleActivation($event, item)"
                    small
                    no-margin)

          v-dialog(
            width="380"
            v-if="tokenCreateModal.showModal"
            :value="tokenCreateModal.showModal"
            @input="closeTokenModal")
            create-token-modal(@close="closeTokenModal" @on-update="successCreation")
          v-dialog(
            width="380"
            v-if="tokenUpdateModal.showModal"
            :value="tokenUpdateModal.showModal"
            @input="closeTokenModal")
            update-token-modal(@close="closeTokenModal" :tokenData="tokenUpdateModal.modalData" @on-update="successUpdate")

          .kt-form__actions
            .position-right
              v-btn(@click="showCreateTokenPopup")
                | {{ 'ui.buttons.new_token' | translate}}
                icon-plus.ml-1
</template>

<script>
import iconEdit from '@/components/svg/global/PencilIcon'
import iconPlus from '@/components/svg/global/PlusIcon'
import deleteIcon from '@/components/svg/global/TrashBoxIcon'
import emptyTableIcon from '@/components/svg/PersonalTokens_emptyIcon'

import api from '@/api'
import throttle from 'lodash.throttle'

import createTokenModal from './CreateTokenModal'
import updateTokenModal from './UpdateTokenModal'
import moment from 'moment'

export default {
  name: 'AccessToken',
  components: {
    iconPlus,
    createTokenModal,
    deleteIcon,
    iconEdit,
    updateTokenModal,
    emptyTableIcon
  },
  mounted () {
    Promise.all([
      this.getTokensList()
    ]).then(() => {
      this.loaded = true
    })
  },

  data: () => ({
    tokenCreateModal: {
      showModal: false,
      modalData: null
    },
    tokenUpdateModal: {
      showModal: false,
      modalData: null
    },
    tokensList: [],
    loaded: false,
    currentSortLabel: null,
    sortedLabels: {
      title: false,
      expiresAt: false,
      isActive: false
    }
  }),

  methods: {
    convertedData (data) {
      if (data) return moment(data).format('DD.MM.YYYY')

      return this.$t('permissions.never')
    },

    onSortTable (fieldName, order) {
      const params = {
        sort: order ? `-${fieldName}` : fieldName
      }

      this.getTokensList(params)
      for (const key in this.sortedLabels) {
        if (key === fieldName) {
          this.sortedLabels[key] = !this.sortedLabels[key]
          this.currentSortLabel = fieldName
        } else {
          this.sortedLabels[key] = false
        }
      }
    },

    async getTokensList (params) {
      const { data } = await api.settings.tokens.getTokensList(params)

      this.tokensList = data
    },

    closeTokenModal () {
      this.tokenCreateModal = {
        showModal: false,
        modalData: null
      }
      this.tokenUpdateModal = {
        showModal: false,
        modalData: null
      }
    },
    showCreateTokenPopup () {
      this.tokenCreateModal.showModal = true
    },

    async deleteToken (payload) {
      await api.settings.tokens.deleteToken(payload)
      await this.getTokensList()
    },

    async updateBadge (status, item, fetch = true) {
      let payload = {
        title: item.title,
        isActive: status
      }
      await api.settings.tokens.updateToken(payload, item.id)
      if (fetch) await this.getTokensList()
    },

    successCreation () {
      this.getTokensList()
    },

    toggleActivation: throttle(function (status, item) {
        item.isActive = !item.isActive
        this.updateBadge(status, item, false)
      }, 1000),

    changeName (name, item) {
      item.title = name
      this.updateBadge(item.isActive, item)
    },

    editToken (index) {
      this.tokenUpdateModal = {
        showModal: true,
        modalData: this.tokensList[index]
      }
    },
    successUpdate () {
      this.getTokensList()
      this.closeTokenModal()
    }

  }

}
</script>

<style lang="scss" scoped>

.wrapper {
  min-height: 300px;

  .title, .subtitle {
    padding: 0 24px
  }

  .v-table__body .v-table__row:nth-of-type(odd) {
    background: none !important;
  }

  .v-table__body .v-table__row:nth-of-type(even) {
    background: #E3F6F1;
  }

  .v-table__head {
    .v-table__row {
      th:first-child {
        padding-left: 24px;
      }
      th {
        font-weight: 400;
      }
    }
  }

  .v-table__row {
    td:first-child {
      padding-left: 24px;
    }
    td {
      font-size: 16px;
    }

    .statusDot {
      margin-right: 8px;
    }
  }

  .kt-form__actions {
    margin-top: 32px;
    margin-right: 24px;
  }
}

.cols {
  display: flex;
  margin-top: 40px;

  .col {
    width: 50%;
  }

  .col-item {
    height: 60px;
    display: flex;
    align-items: center;

    label {
      margin: 0;
    }
  }
}

.button-element {
  cursor: pointer;
  margin-right: 15px;
}
</style>
<style lang="scss">
.accessTokens{
  .emptyIcon {
    display: flex;
    margin: 48px auto;
  }
  .v-card__content {
    padding: 32px 0 24px 0 !important;
  }
}
</style>
